<template>
  <div>
    <div class="page-heading" style="margin-bottom: 10px;">
      <div class="row">
        <div class="col-sm-7 col-md-7 col-lg-7 hidden-xs">
          <div class="heading-content">
            <div class="user-image">
              <img :src="`assets/images/${hourType}.png`" class="img-circle img-responsive"/>
            </div>
            <div class="heading-title">
              <h2 class="DINRoundPro-Light">{{hourTypeText}}, {{info.fname}}</h2>
              <p>
                Aqui você pode ter uma visão geral sobre as suas
                movimentações financeira.
              </p>
            </div>
          </div>
        </div>
        <div class="hidden-sm hidden-md hidden-lg col-xs-6 visible-xs">
          <div class="heading-content">
            <div class="user-image">
              <img :src="`assets/images/${hourType}.png`" class="img-circle img-responsive"/>
            </div>
            <div class="heading-title">
              <h2 class="DINRoundPro-Light">{{hourTypeText}}, {{info.fname}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-5 col-xs-6 order-xs-2">
          <h3 class="block text-right title-balance DINRoundPro-Light" style="font-size: 40px; color: #4B4B4B;" v-if="!isLoading">
            {{totalAccount | currency}}
          </h3>
          <h4 class="text-right" style="color: #AFAFAF;">Saldo em conta</h4>
          <div class="shimmerBG title-line" v-if="isLoading" style="height: 30px; width: 100px; float:right;"></div>
        </div>
      </div>
    </div>
    <div class="row overview-widgets">
      <div class="col-lg-12">
        <div class="owl-nav" style="position: relative; width: 35%; top: 0; margin: 0 auto; margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 col-md-8">
        
        <div class="card" style="min-height: 280px !important;">
          <div class="card-body">
            <div v-if="isLoading" class="row expense-info">
              <section class="col-sm-6">
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
                <div style="margin-top:20px;"></div>
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
                <div style="margin-top:20px;"></div>
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
              </section>
              <section class="col-sm-6">
                <div class="shimmerBG title-line" style="height:30px; width: 60%;"></div>
                <div class="shimmerBG title-line end" style="height:25px;"></div>
              </section>
            </div>
            <div v-else class="row relative">
              <div class="col-sm-6">
                <div class="transaction-amount m-top-15">
                  <!-- item -->
                  <div class="transaction-amount-item">
                    <div class="transaction-icon">
                      <i
                        class="mdi mdi-checkbox-blank-circle text-primary"
                      ></i>
                    </div>
                    <div class="transaction-info">
                      <strong class="info-transactions">{{report.transactions.report.total_income | currency}}</strong>
                      <span class="DINRoundPro">Receitas</span>
                    </div>
                  </div>
                  <!-- item -->
                  <div class="transaction-amount-item">
                    <div class="transaction-icon">
                      <i
                        class="mdi mdi-checkbox-blank-circle text-danger"
                      ></i>
                    </div>
                    <div class="transaction-info">
                      <strong class="info-transactions">{{(parseFloat(report.transactions.report.total_expense) + parseFloat(report.transactions.report.total_card)) | currency}}</strong>
                      <span class="DINRoundPro">Despesas</span>
                    </div>
                  </div>
                </div>

                <div class="transaction-visual">
                  <v-chart class="chart" :option="option" />
                </div>
              </div>
              <div class="col-sm-6">
                <h3 class="text-left block DINRoundPro" style="color: #938d8d">
                  Balanço mensal
                </h3>
                <h1
                  class="text-left font-w-100 text-default DINRoundPro-Light"
                  style="font-size: 25px"
                >
                  {{report.transactions.report.total | currency}}
                </h1>

                <section class="text-center lets-info">
                  <div class="fast-add">
                    <ul class="btn-list">
                      <li class="btn-action">
                        <router-link
                          :to="'/expense'"
                          class="expense"
                        ><i class="icon-expense-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            DESPESAS
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/income'"
                          class="earning"
                        ><i class="icon-earning-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            RECEITAS
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/transfer'"
                          class="transfer"
                        ><i class="icon-transfer-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            TRANSF.
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/import'"
                          class="import"
                        ><i class="icon-import-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            IMPORTAR
                          </p>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
            <div class="card-filter" v-tooltip.top="'Filtrar faturas'" :class="{'active' : this.show_cards}" @click="show_cards = !show_cards, request()">
              <i class="fa fa-credit-card"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-4" >
        <div class="card" style="min-height: 280px !important;">
          <h4 class="text-left block" style="float: left">Contas fixas</h4>
          <div style="float: right">
            <router-link
              :to="'/billing'"
              ><i class="mdi mdi-adjust hide" style="font-size: 15px"></i>
              <img :src="`/assets/images/icons/png/btn_plus.png`" style="width: 30px;"  class="opacity-hover">
            </router-link>
          </div>
          <div style="clear: both;"></div>
          <div class="card-body p-t-8">
            <section class="shimmer-loader" v-if="isLoading">
              <div class="shimmerBG title-line"></div>
              <div>
                  <div class="shimmerBG title-line end"></div>
                  <br>
                  <div class="shimmerBG title-line"></div>
                  <div class="shimmerBG content-line" style="height: 25px;"></div>
              </div>
            </section>
            <section class="text-center" v-else>
              <h4 class="text-left block" style="color: #a6a6a6; font-weight: 500; font-size: 20px;">Contas a receber</h4>
              <h1 class="text-success text-left font-w-100" style="margin: 0px;">
                {{report.transactions.report.bill_income_open | currency}}
              </h1>
              <h4 class="text-left block" style="color: #a6a6a6; font-weight: 500; font-size: 20px; margin-top: 18px;">Contas a pagar</h4>
              <h1 class="text-danger text-left font-w-100" style="margin: 0px;">
                {{report.transactions.report.bill_expense_open | currency}}
              </h1>
            </section>

            <div></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4">
        <div class="card">
          <div class="card-body no-padding relative">
            <h4 class="text-left block" style="float: left">Orçamento mensal</h4>
            <div style="float: right">
              <router-link
                :to="'/budget'"
                ><i class="mdi mdi-adjust hide" style="font-size: 15px"></i>
                <img :src="`/assets/images/icons/png/btn_plus.png`" style="width: 30px;" class="opacity-hover">
              </router-link
              >
            </div>
            <div style="clear: both"></div>
            <section class="shimmer-loader" v-if="isLoading">
              <div class="shimmerBG title-line"></div>
              <div>
                  <div class="shimmerBG title-line end"></div>
                  <br>
                  <div class="shimmerBG title-line"></div>
                  <div class="shimmerBG content-line" style="height: 25px;"></div>
              </div>
            </section>
            <section class="text-center" v-else>
              <div class="row">
                <div class="col-sm-6">
                  <h4 class="text-left block" style="color: #a6a6a6; font-weight: 500; font-size: 20px;">Metal total</h4>
                  <h3 class="text-success text-left font-w-100" style="margin: 0px;">
                    {{this.report.transactions.report.budget.report.budgets.total | currency}}
                  </h3>
                  <h4 class="text-left block" style="color: #a6a6a6; font-weight: 500; font-size: 20px; margin-top: 18px;">Total gasto</h4>
                  <h3 class="text-danger text-left font-w-100" style="margin: 0px;">
                    {{this.report.transactions.report.budget.report.budgets.totalSpent | currency}}
                  </h3>
                </div>
                <div class="col-sm-6">
                  <section class="text-center">
                    <svg
                      data-v-31a768e4=""
                      width="140"
                      height="140"
                      xmlns="http://www.w3.org/2000/svg"
                      class="circle-chart"
                      viewBox="-3 0 40 30"
                    >
                      <circle
                        class="circle-chart__background"
                        stroke="#efefef"
                        stroke-width="2"
                        fill="none"
                        cx="16.91549431"
                        cy="16.91549431"
                        r="15.91549431"
                      />
                      <circle
                        class="circle-chart__circle"
                        stroke="#ff1a1a"
                        stroke-width="2"
                        :stroke-dasharray="`${this.report.transactions.report.budget.report.budgets.spentPercent},100`"
                        stroke-linecap="round"
                        fill="none"
                        cx="16.91549431"
                        cy="16.91549431"
                        r="15.91549431"
                      />
                      <g class="circle-chart__info">
                        <text
                          class="circle-chart__percent font-w-100"
                          x="16.91549431"
                          y="17"
                          alignment-baseline="central"
                          text-anchor="middle"
                          font-size="8"
                        >
                          {{this.report.transactions.report.budget.report.budgets.spentPercent}}%
                        </text>
                      </g>
                    </svg>
                  </section>
                </div>
                <div class="col-sm-12" style="padding-top: 15px;">
                  <progress-day :day_progress="this.report.transactions.report.budget.report.day_progress" :day_end="this.report.transactions.report.budget.report.day_end" :day_start="this.report.transactions.report.budget.report.day_start"/>
                </div>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 col-md-8">
        <div class="card card-min-home">
          <div
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              no-padding
            "
          >
            <h4 class="text-left block" style="float: left">
              Top categorias
            </h4>
            <div style="float: right">
              <router-link
                :to="'/cashflow'"
                ><i class="mdi mdi-adjust hide" style="font-size: 15px"></i>
                <img :src="`/assets/images/icons/png/btn_plus.png`" style="width: 30px;"  class="opacity-hover">
              </router-link
              >
            </div>
            <div>
              <div style="clear: both"></div>
            </div>
            <div v-if="isLoading" class="row">
              <div class="col-lg-7">
                <div class="shimmerBG" style="width: 150px; height: 150px; border-radius: 50%;margin: 0 auto;"></div>
              </div>
              <div class="col-lg-5">
                <br>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 50px"></div>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 50px"></div>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 50px"></div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 col-lg-12" v-if="this.report.transactions.report.categories.length == 0">
                <div class="row ng-scope" v-if="this.report.transactions.report.categories.length == 0">
                  <img :src="`assets/images/transaction.png`" class="nothing-image" style="width:18%; !important">
                  <h3 class="nothing-text">Nenhum lançamento</h3>
                </div>
              </div>
              <div class="col-md-7 col-lg-7" v-else>
                <v-chart class="chart" :option="optionCat" />
              </div>
              <div class="col-md-5 col-lg-5">
                <div class="chart-legend chart-legend--vertical" v-for="category in this.report.transactions.report.categories" :key="category.id">
                  <span class="chart-legend-item relative c-pointer" v-if="category.category.parent == null" @click="categoryDetail('category-detail-modal', category, category.total)">
                    <div class="icon-category-overview" :style="`background: ${category.color}`">
                      <img :src="`assets/images/icons/png/${category.icon}.png`" />
                    </div>
                    {{category.name}}
                    <strong style="position: absolute; right: 10px"
                      >{{category.total | currency}}</strong
                    >
                  </span>
                  <span class="chart-legend-item relative c-pointer" v-else @click="categoryDetail('category-detail-modal', category.category.parent, category.total)">
                    <div class="icon-category-overview" :style="`background: ${category.category.parent.color}`">
                      <img :src="`assets/images/icons/png/${category.category.parent.icon}.png`" />
                    </div>
                    {{category.category.parent.name}}
                    <strong style="position: absolute; right: 10px"
                      >{{category.total | currency}}</strong
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-12 col-lg-12 credit-cards-container credit-cards-columns">
        <div class="card">
          <div
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              no-padding
            "
          >
            <h4 class="text-left block" style="float: left">Faturas</h4>
            <div>
              <div style="clear: both"></div>
            </div>
            <div v-if="isLoading" class="row">
              <div class="col-sm-8">
                <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left;"></div>
                <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left; margin-left:10px;"></div>
              </div>
              <div class="col-sm-4">
                <div class="shimmerBG title-line" style="width: 30%; margin-top:15px; height: 20px;"></div>
                <div class="shimmerBG title-line" style="width: 20%; margin-top:15px; height: 30px;"></div>
              </div>
              <div style="clear:both;"></div>
              <br>
              <br>
              <br>
            </div>
            <div v-else class="row relative">
              <div class="mini-tab-root tab-elevation1 tab-rounded tab-card-invoice"  style="margin-right: 10px; float:right;position: absolute; right: 10px;top: -35px;">
                <div class="mini-tab-content">
                    <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                      <div class="mini-tab-flex">
                          <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                            <span>
                              Faturas abertas
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </button>
                          <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                            <span>
                              Faturas fechadas
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </button>
                      </div>
                      <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
                    </div>
                </div>
              </div>
              <div class="columns is-gapless">
                <div class="column is-8 relative">
                  <!--Box-->
                  <div class="inner-box">
                    <div class="cards-wrapper">
                      <div class="controls">
                        <button type="button" class="btn btn-blue dropdown-toggle color-white btn-blue-shadow btn-add-new-item" v-modal="{ target: 'create-card-modal' }"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10.833 9.167V5H9.167v4.167H5v1.666h4.167V15h1.666v-4.167H15V9.167h-4.167zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path></svg></span></button>
                      </div>

                      <!--Carousel-->
                      <div class="cards-carousel">
                        <!-- Slider main container -->
                        <div
                          class="
                            cards-carousel-inner
                            slick-initialized slick-slider slick-dotted
                          "
                        >
                          <!--Carousel Item-->
                          <div v-if="tabActive == 'open'">
                            <div v-if="this.cards.opened_invoice.length > 0">
                              <carousel :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :perPageCustom="[[480, 1], [1115, 2]]" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                                <slide v-for="(card, i) in this.cards.opened_invoice" :key="i" :data-index="i" @slideclick="handleSlideOpenCard">
                                  <div
                                    class="
                                      cards-carousel-item
                                      slick-slide slick-cloned
                                    "
                                    data-slick-index="6"
                                    id=""
                                    aria-hidden="true"
                                    tabindex="-1"
                                  >
                                    <div class="ccard credit-card-shadow ccard-hover ccard-card-list-item">
                                      <div class="shape"></div>
                                      <div class="top">
                                        <img
                                          class="light-image inactive"
                                          :src="`/assets/images/cards/${card.brand}.png`"
                                          alt=""
                                        />
                                        {{ card.name }}
                                        <div class="card-date">
                                          <span>Fecha em</span>
                                          <strong>{{ card.close_date | moment("DD") }} de {{ card.close_date | moment("MMM") }}</strong>
                                          <span>Valor parcial</span>
                                          <strong>{{ card.open_value | currency }}</strong>
                                        </div>
                                      </div>
                                      <div class="card-info-pay">
                                        <span class="badge badge-info">Vence em: {{ card.payment_date | moment("DD") }} de {{ card.payment_date | moment("MMM") }}</span>
                                      </div>
                                      <div class="bottom">
                                        <span style="font-size: 13px;">{{ card.spent | currency }} de {{ card.card_limit | currency }}</span>
                                        <div class="progress progress-card" style="height: 10px !important;" :class="{'progress-bar-danger-alt': card.progress > 85, 'progress-bar-warning-alt': card.progress > 70 && card.progress < 85, 'progress-bar-success-alt': card.progress < 70 }">
                                          <div class="progress-bar" :class="{'progress-bar-danger': card.progress > 85, 'progress-bar-warning': card.progress > 70 && card.progress < 85, 'progress-bar-success': card.progress < 70 }" role="progressbar" :style="`width:${card.progress}%`">
                                            <span class="hide">{{ card.progress }}%</span>
                                          </div>
                                        </div>
                                        <span style="font-size: 13px; margin-bottom: 0px; font-weight: 100;">Limite disponível: <strong>{{ card.available | currency }}</strong></span>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                              </carousel>
                            </div>
                          </div>
                          <div v-if="tabActive == 'closed'">
                            <div v-if="this.cards.invoice_closed.length > 0">
                              <carousel :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                                <slide v-for="(card, i) in this.cards.invoice_closed" :key="i" :data-index="i" @slideclick="handleSlideCloseCard">
                                  <div
                                    class="
                                      cards-carousel-item
                                      slick-slide slick-cloned
                                    "
                                    data-slick-index="6"
                                    id=""
                                    aria-hidden="true"
                                    tabindex="-1"
                                  >
                                    <div class="ccard credit-card-shadow ccard-hover" style="height: 195px; width: 300px;">
                                      <div class="shape"></div>
                                      <div class="top">
                                        <img
                                          class="light-image inactive"
                                          :src="`/assets/images/cards/${card.brand}.png`"
                                          alt=""
                                        />
                                        {{ card.name }}
                                        <div class="card-date">
                                          <span>Fechou em</span>
                                          <strong>{{ card.cinvo_close_date | moment("DD") }} de {{ card.cinvo_close_date | moment("MMM") }}</strong>
                                          <span>Valor total</span>
                                          <strong>{{ card.close_value | currency }}</strong>
                                        </div>
                                      </div>
                                      <div class="card-info-pay" v-if="!card.payment_due">
                                        <span class="badge badge-info">Vence em: {{ card.cinvo_payment_date | moment("DD") }} de {{ card.cinvo_payment_date | moment("MMM") }}</span>
                                      </div>
                                      <div class="card-info-pay" v-if="card.payment_due">
                                        <span class="label label-warning">Fatura vencida</span>
                                      </div>
                                      <div class="bottom">
                                        <span style="font-size: 13px;">{{ card.spent | currency }} de {{ card.card_limit | currency }}</span>
                                        <div class="progress progress-card" style="height: 10px !important;" :class="{'progress-bar-danger-alt': card.progress > 85, 'progress-bar-warning-alt': card.progress > 70 && card.progress < 85, 'progress-bar-success-alt': card.progress < 70 }">
                                          <div class="progress-bar" :class="{'progress-bar-danger': card.progress > 85, 'progress-bar-warning': card.progress > 70 && card.progress < 85, 'progress-bar-success': card.progress < 70 }" role="progressbar" :style="`width:${card.progress}%`">
                                            <span class="hide">{{ card.progress }}%</span>
                                          </div>
                                        </div>
                                        <span style="font-size: 13px; margin-bottom: 0px; font-weight: 100;">Limite disponível: <strong>{{ card.available | currency }}</strong></span>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                              </carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="this.cards.opened_invoice.length == 0 && tabActive == 'open'" class="w-100">
                        <img :src="`/assets/images/credit-card.png`" style="opacity: 0.2; margin-top: 5px; width: 15%;" class="nothing-image"/>
                        <h4 class="nothing-image text-center" style="width: 40%">Nenhum cartão cadastrado</h4>
                        <br>
                        <br>
                      </div>
                      <div v-if="this.cards.invoice_closed.length == 0 && tabActive == 'closed'" class="w-100">
                        <img :src="`/assets/images/invoice.svg`" style="opacity: 0.2; margin-top: 5px; width: 15%;" class="nothing-image"/>
                        <h4 class="nothing-image text-center" style="width: 40%">Nenhuma fatura fechada</h4>
                        <br>
                        <br>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <!--Box-->
                  <div class="inner-box">
                    <div class="box-title">
                      <h3 v-if="tabActive == 'open'">Valor parcial</h3>
                      <h3 v-if="tabActive == 'closed'">Valor total</h3>
                    </div>
                    <div
                      class="card-balance"
                    >
                      <span class="font-w-600 DINRoundPro" v-if="tabActive == 'open'" style="font-size: 25px;">{{this.cards.opened_value | currency}}</span>
                      <span class="font-w-600 DINRoundPro" v-if="tabActive == 'closed'" style="font-size: 25px;">{{this.cards.closed_value | currency}}</span>
                    </div>
                    
                    <!--Balance-->
                    <div class="card-balance-wrap">
                      <div class="box-title">
                        <span style="color: #a2a5b9; margin-top: 20px;">Limite total</span>
                      </div>
                      <div
                        class="card-balance text-success"
                        style="margin-bottom: 10px"
                      >
                        <span class="text-success font-w-600 DINRoundPro" style="font-size: 20px;">{{this.cards.total_limit | currency}}</span>
                      </div>
                      <div class="card-balance-stats" style="margin-top: 0px;">
                        <div class="card-balance-stat">
                          <div class="stat-title">
                            <span>Disponível</span>
                          </div>
                          <div class="stat-block">
                            <div class="stat-icon is-up">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-arrow-right"
                              >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline
                                  points="12 5 19 12 12 19"
                                ></polyline>
                              </svg>
                            </div>
                            <div class="stat-text">
                              <span>{{this.cards.total_available | currency}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="card-balance-stat">
                          <div class="stat-title">
                            <span>Gastos</span>
                          </div>
                          <div class="stat-block">
                            <div class="stat-icon is-down">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-arrow-right"
                              >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline
                                  points="12 5 19 12 12 19"
                                ></polyline>
                              </svg>
                            </div>
                            <div class="stat-text">
                              <span>{{this.cards.total_spent | currency}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-12 col-lg-12">
          <div class="card">
              <div class="card-body no-padding relative">
                  <div class="loading-overlay-inside" v-if="isLoading" style="background-color:rgba(298, 294, 290, 0.9);"><div class="loader-box"><div class="circle-loader"></div></div></div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-12 col-md-12 hide">
                      <div>
                        <input type="checkbox" id="for_is_card" v-model="no_card" @change="request">
                        <label style="margin-top: 10px; margin-left: 10px;" for="for_is_card" class="to-left">Filtrar despesas de cartão</label>
                      </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3 col-xs-12  figure-stats">
                      <div class="figure-section">
                          <p>Total de entradas</p>
                          <h2 class="text-primary reports-income font-w-100 income-title-h2">{{this.boxFlow.income.total | currency}}</h2>
                      </div>
                      <div class="figure-section">
                          <p>Total de saídas</p>
                          <h2 class="text-danger reports-expenses font-w-100 expense-title-h2">{{this.boxFlow.expense.total | currency}}</h2>
                      </div>
                      <div class="figure-section">
                          <p>Balanço mensal</p>
                          <h2 class="reports-total font-w-100">{{this.boxFlow.total.total | currency}}</h2>
                          <h5 class="font-w-100 reports-period" style="position: absolute;bottom: 0;"></h5>
                      </div>
                      <div>
                        <router-link
                            class="info-label report"
                            :to="'/cashflow'">
                          <i class="icon-report"></i>
                          <p>Ver relatórios</p>
                        </router-link>
                        <br>
                      </div>
                    </div>
                    <div class="col-sm-9 col-md-9 col-lg-9">
                      <h4 class="text-center block">Fluxo de caixa</h4>
                      <div class="card-filter" v-tooltip.top="'Filtrar faturas'" :class="{'active' : this.show_cards}" @click="show_cards = !show_cards, request()">
                        <i class="fa fa-credit-card"></i>
                      </div>
                      <v-chart class="chart-line" :option="optionChart2" />
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-sm-12 col-lg-12 col-lg-12 credit-cards-container">
        <div class="card">
          <div
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              no-padding
            "
          >
            <h4 class="text-left block" style="float: left">Contas</h4>
            <div>
              <div style="clear: both"></div>
            </div>
            <div v-if="isLoadingWallet" class="row">
              <div class="col-sm-8">
                <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left;"></div>
                <div class="shimmerBG title-line" style="width: 40%; margin-top:15px; height: 150px; float:left; margin-left:10px;"></div>
              </div>
              <div class="col-sm-4">
                <div class="shimmerBG title-line" style="width: 30%; margin-top:15px; height: 20px;"></div>
                <div class="shimmerBG title-line" style="width: 20%; margin-top:15px; height: 30px;"></div>
              </div>
              <div style="clear:both;"></div>
              <br>
              <br>
              <br>
            </div>
            <div v-else class="row">
              <div class="columns is-gapless">
                
                <div class="column is-8">
                  <!--Box-->
                  <div class="inner-box relative">
                    <div class="cards-wrapper" style="padding-right: 35px;">
                      <div class="controls">
                        <button type="button" v-modal="{ target: 'create-account-modal', data: {color: '#933DA8'} }" class="btn btn-blue dropdown-toggle color-white btn-blue-shadow btn-add-new-item"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10.833 9.167V5H9.167v4.167H5v1.666h4.167V15h1.666v-4.167H15V9.167h-4.167zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path></svg></span></button>
                      </div>
                      <div class="cards-carousel">
                        <div class=" cards-carousel-inner slick-initialized slick-slider slick-dotted">
                          <carousel :perPageCustom="[[480, 1], [1115, 2]]" :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                            <slide v-for="(wallet, i) in this.wallets.wallets" :key="i" :data-index="i" @slideclick="handleSlideWallet">
                              <div class="item rounded card bg-white mt-5 box-card accounts-list relative" :style="`background: ${wallet.color} !important; width: 280px !important;`">
                                <div class="padding-10">
                                  <h4>{{wallet.name}}</h4>
                                  <section class="text-center mt-15">
                                    <h1 class="text-left font-w-100">
                                      <span>{{wallet.balance | currency}}</span>
                                    </h1>
                                  </section>
                                  <span class="label label-success info-label-wallet" v-if="wallet.automatic">Automática</span>                            
                                  <div></div>
                                </div>
                              </div>
                            </slide>
                          </carousel>
                        </div>
                      </div>
                    </div>
                    <div class="row ng-scope" v-if="this.wallets.wallets.length == 0">
                      <img :src="`assets/images/bank.svg`" class="nothing-image w-15p" style="width: 10% !important;">
                      <h3 class="nothing-text">Nenhuma conta cadastrada</h3>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <!--Box-->
                  <div class="inner-box">
                    <div class="box-title">
                      <h3>Saldo Total</h3>
                    </div>

                    <!--Balance-->
                    <div class="card-balance-wrap">
                      <div
                        class="card-balance text-success"
                        style="margin-bottom: 10px"
                      >
                        <span class="text-success font-w-600 DINRoundPro">{{this.wallets.total_balance | currency}}</span>
                      </div>
                      <div>
                        <br />
                        <router-link :to="'/account'" class="info-label report"> <p>Ver contas</p></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { Carousel, Slide } from 'vue-carousel';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { graphic } from 'echarts';
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import CalendarCo from '../components/Calendar.vue';
import { moneyFormat } from '@/plugins/functions';
import ProgressDay from '../components/ProgressDay.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    VChart,
    Carousel,
    Slide,
    CalendarCo,
    ProgressDay,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      show_cards: true,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      yearStart: 2023,
      to: null,
      from: null,
      no_card: false,
      report: {},
      wallets: {},
      tabActive: 'open',
      boxFlow: {
        income: {},
        expense: {},
        total: 0,
      },
      cards: {
        opened_invoice: {},
        invoice_closed: {},
      },
      isLoading: true,
      isLoadingWallet: true,
      isLoadingCards: true,
      optionChart2: {
        legend: {
          data: ['Despesas', 'Receitas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var template = '';
            var balance = '';
            if(params[1]) {
              balance = moneyFormat((params[1].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }
            if(params[0]) {
              balance = moneyFormat((params[0].data));
              template = `${params[0].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }

            if(params[1] && params[0]) {
              balance = moneyFormat((params[1].data) - Math.abs(params[0].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1> <hr style="margin-top:5px;margin-bottom:5px;"> <p style="margin:0px; color: #cccccc !important;">Entradas: <span style="text-align: right; display:block; float: right;"> R$ ${moneyFormat(params[1].data)}</span></p><p style="color: #cccccc !important;">Saídas: <span style="text-align: right; display:block; float: right;">R$ ${moneyFormat(params[0].data)}</span></p>`;
            }
            return template;
          }
        },
        xAxis: {
          data: [],
          silent: false,
          axisTick: {
            alignWithLabel: true,
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [{
            name: 'Despesas',
            type: 'line',
            smooth: false,
            stack: 'transactions',
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(255,26,26,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(255,26,26,0.15)'
                },{
                    offset: 1,
                    color:'rgba(255,26,26,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#ff1a1a",
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 10;
            }
        }, {
            name: 'Receitas',
            type: 'line',
            stack: 'transactions',
            barMaxWidth: 10,
            smooth: false,
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(55,163,59,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(55,163,59,0.15)'
                },{
                    offset: 1,
                    color:'rgba(55,163,59,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#18b272"
              }
            },
            data: [
              2, 2, 10, 20, 4
            ],
            animationDelay: function (idx) {
              return idx * 10 + 100;
            }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      optionCat: {
        height: 190,
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              '</h3> <span class="text-left" style="display:block; color: #000 !important;">' +
              params.percent +
              "%</span></span>";

            return template;
          },
        },
        toolbox: {
          show: false,
        },
        color: ["#f62d51", "#dddddd", "#ffbc34"],
        calculable: true,
        series: [
          {
            name: "Top",
            type: "pie",
            roseType: "radius",
            radius: [30, 70],
            center: ["50%", "50%"],
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: [],
          },
        ],
      },
      monthsList: [
        { id: '01', name: 'Janeiro', index: 0},
        { id: '02', name: 'Fevereiro', index: 1},
        { id: '03', name: 'Março', index: 2},
        { id: '04', name: 'Abril', index: 3},
        { id: '05', name: 'Maio', index: 4},
        { id: '06', name: 'Junho', index: 5},
        { id: '07', name: 'Julho', index: 6},
        { id: '08', name: 'Agosto', index: 7},
        { id: '09', name: 'Setembro', index: 8},
        { id: '10', name: 'Outubro', index: 9},
        { id: '11', name: 'Novembro', index: 10},
        { id: '12', name: 'Dezembro', index: 11},
      ],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  created() {
    moment.locale('pt_BR');
    window.scrollTo(0, 0);
    var hr = (new Date()).getHours();
    console.log('Horas', hr);
    if(hr > 18) {
      this.hourType = 'night';
      this.hourTypeText = 'Boa noite';
    }
    if(hr < 12) {
      this.hourType = 'sun';
      this.hourTypeText = 'Bom dia';
    }
    if(hr > 12 && hr <= 18) {
      this.hourType = 'afternoon';
      this.hourTypeText = 'Boa tarde';
    }
    this.monthStart = new Date().getMonth();
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    console.log('Mês', this.monthStart);
    pace.start();
    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.request();
    this.$root.$on('register.transaction', this.request);
    this.$root.$on('register.card', this.request);
    this.$root.$on('register.account', this.request);
  },
  methods: {
    categoryDetail(id, item, total) {
      item.monthStart = this.monthStart;
      item.monthName = this.monthsList[this.monthStart].name;
      item.from = this.from;
      item.total = total;
      item.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.$root.$emit('show.modal', id, item);
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      switch (tab) {
        case 0:
          this.tabActive = 'open';
          break;
        case 1:
          this.tabActive = 'closed';
          break;
        default:
          break;
      }
    },
    request() {
      this.optionChart2.xAxis.data = [];
      this.optionChart2.series[0].data = [];
      this.optionChart2.series[1].data = [];
      this.optionCat.series[0].data = [];
      this.option.series[0].data = [];

      AppService.getInfo({
        from: this.from,
        to: this.to,
        home_get: true,
        remove_transfer: true,
        invoice_date: true,
        trans_card: true,
        show_cards: this.show_cards,
      }).then(
        (response) => {
          this.isLoading = false;
          this.report = response;
          this.totalAccount = response.total_balance;
          if (this.report.transactions.report.income_percent > 0 || this.report.transactions.report.expense_percent ) {
            this.option.series[0].data.push({
              value: this.report.transactions.report.income_percent, name: "Receitas",
            });
            this.option.series[0].data.push({
              value: this.report.transactions.report.expense_percent, name: "Despesas",
            });
          }
          this.optionCat.color = response.transactions.report.colors_category;
          response.transactions.report.categories.forEach(element => {
            this.optionCat.series[0].data.push(
              {
                value: element.percent,
                name: element.category.parent ? element.category.parent.name : element.category.name,
                label: {
                  formatter: function (params) {
                    console.log(params);
                    var template = '{Showers|}';
                    return template;
                  },
                  backgroundColor: element.category.parent ? element.category.parent.color : element.category.color ? element.category.color : '#18b272',
                  borderColor: "#fff",
                  borderWidth: 1,
                  borderRadius: 30,
                  padding: 8,
                  rich: {
                    backgroundColor: '#ccc',
                    Showers: {
                      height: 20,
                      align: "center",
                      backgroundColor: {
                        image: `assets/images/icons/png/${element.category.parent ? element.category.parent.icon : element.category.icon}.png`,
                      },
                    },
                  },
                },
              }
            );
          });
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getCards({
        from: this.from,
        to: this.to,
      }).then(
        (response) => {
          console.log('Cards');
          console.log(response);
          this.cards = response;
          this.isLoadingCards = false;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getWallets({}).then(
        (response) => {
          this.isLoadingWallet = false;
          this.wallets = response;
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.boxFlow({
        from: this.from,
        to: this.to,
        get_group: true,
        invoice_date: true,
        trans_card: true,
        show_cards: this.show_cards,
      }).then(
        (response) => {
          this.boxFlow = response;
          this.optionChart2.xAxis.data = this.boxFlow.chart.label;
          this.optionChart2.series[0].data = this.boxFlow.chart.expense;
          this.optionChart2.series[1].data = this.boxFlow.chart.income;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    changeDate (event) {
      console.log(event);
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.request();
    },
    handleSlideOpenCard (dataset) {
      this.$router.push(`/card/detail/${this.cards.opened_invoice[dataset.index].id}`);
    },
    handleSlideCloseCard (dataset) {
      this.$router.push(`/card/detail/${this.cards.invoice_closed[dataset.index].id}`);
    },
    handleSlideWallet (dataset) {
      this.$router.push(`/account/detail/${this.wallets.wallets[dataset.index].id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 30px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.card-min-home {
  min-height: 310px;
}
@media only screen and (max-width: 1184px){
  
}
@media only screen and (max-width: 1100px){
  .figure-section h2 {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 1023px){
  .card-min-home{
    min-height: 350px;
  }
}
@media only screen and (max-width: 946px){
  .figure-section h2 {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 893px){
  .figure-section h2 {
    font-size: 20px !important;
  }
  .branding {
    width: 50px !important;
  }
}
.card-filter {
  float: right;
  position: absolute;
  right: 25px;
  top: 5px;
  cursor: pointer;
}
.card-filter:hover {
  color: #18b272;
}
.card-filter.active {
  color: #18b272;
}
</style>
